import { useFetchInstance } from "~/api";
import {
  ForgotForm,
  PasswordForm,
  RecoveryPasswordResponse,
  SignUpData,
  VerifyEmailResponse,
} from "@/types/api/auth.types";
import { AddressData, AddressResponse } from "@/types/api/cart.types";
import { UserResponse } from "~/types/api/user.types";
import { UserSubscriptionResponse } from "~/types/api/user-subscriptions.types";

export const userApi = {
  /**
   * Recovery password
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PatchApiV1UsersRecoverPassword
   * @param data
   * @returns {*}
   */
  recoveryPasswordRequest(data: ForgotForm) {
    return useFetchInstance<RecoveryPasswordResponse>(
      "/users/recover-password",
      {
        method: "PATCH",
        body: data,
      }
    );
  },

  /**
   * Change password by recovery token
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PatchApiV1UsersChangePassword
   * @param data
   * @returns {*}
   */
  changePasswordByRecoveryToken(
    data: PasswordForm & { password_recovery_token: string }
  ) {
    return useFetchInstance("/users/change-password", {
      method: "PATCH",
      body: data,
    });
  },

  /**
   * Verify Email
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PostApiV1UsersVerifyEmail
   * @param data
   * @returns {*}
   */
  verifyEmail(data: SignUpData) {
    return useFetchInstance<VerifyEmailResponse>("/users/verify-email", {
      method: "POST",
      body: data,
    });
  },
  /**
   * Create a new shipping address for a session user
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-User-PostApiV1UsersAddressesShipping
   * @param data
   * @returns {*}
   */
  setUserShippingAddress(data: AddressData) {
    return useFetchInstance("/users/addresses/shipping", {
      method: "POST",
      body: data,
    });
  },
  /**
   * Get shipping address list
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-GetApiV1UsersAddressesShipping
   * @returns {*}
   */
  getUserShippingAddressList() {
    return useFetchInstance<AddressResponse>("/users/addresses/shipping", {
      method: "GET",
      key: "getUserShippingAddressList",
    });
  },

  /**
   * Update a shipping address of a session user
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-User-PutApiV1UsersAddressesShippingId
   * @param data
   * @param id
   * @returns {*}
   */
  updateUserShippingAddress(data: AddressData, id: number) {
    return useFetchInstance(`/users/addresses/shipping/${id}`, {
      method: "PUT",
      body: data,
    });
  },

  /**
   * Set Default Shipping address
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-GetApiV1UsersAddressesShipping
   * @returns {*}
   */
  setDefaultShippingAddress(id: number, params: any) {
    return useFetchInstance(`/users/addresses/shipping/${id}`, {
      method: "PATCH",
      body: params,
    });
  },

  /**
   * get user subscription
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-GetApiV1UsersAddressesShipping
   * @returns {*}
   */
  getUserSubscription() {
    return useFetchInstance<UserSubscriptionResponse>(`/users/subscriptions`);
  },

  /**
   * get user subscription
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-GetApiV1UsersAddressesShipping
   * @returns {*}
   */
  updateUserSubscription(data: number[]) {
    return useFetchInstance<UserSubscriptionResponse>(`/users/subscriptions`, {
      method: "PATCH",
      body: data,
      key: "updateUserSubscription",
    });
  },

  /**
   * Update session user
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-User-PutApiV1Users
   * @param data
   * @returns {*}
   */
  updateUser(data: any) {
    return useFetchInstance<UserResponse>("/users", {
      method: "PUT",
      body: data,
    });
  },

  /**
   * Delete an user shipping address
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-User-DeleteApiV1UsersAddressesShippingId
   * @returns {*}
   * @param id
   */
  deleteUserShippingAddress(id: number) {
    return useFetchInstance(`/users/addresses/shipping/${id}`, {
      method: "DELETE",
      key: "deleteUserShippingAddress",
    });
  },
};
